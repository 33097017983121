import {
  excelExportCorrelationTab,
  excelExportDrawDownTab,
  excelExportReturnTab,
  excelExportSummaryTab,
  printDrowdawnTab,
  printSummaryTab,
  printTab,
} from "../../helpers/Portfolio";
import SnackBarUtils from "../../helpers/SnackBarUtils";

export const summaryTableMenu = {
  beta: {
    id: "beta",
    label: "beta",
  },
  beta_std: {
    id: "beta_std",
    label: "beta std",
  },
  alpha: {
    id: "alpha",
    label: "alpha",
  },
  p_value: {
    id: "p_value",
    label: "p value",
  },
  t_stat: {
    id: "t_stat",
    label: "t stat",
  },
  jensen_alpha: {
    id: "jensen_alpha",
    label: "jensen alpha",
  },
  // jensen_sharpe: {
  //   id: "jensen_sharpe",
  //   label: "jensen sharpe",
  // },
  beta_plus: {
    id: "beta_plus",
    label: "beta plus",
  },
  beta_minus: {
    id: "beta_minus",
    label: "beta minus",
  },
};

export const summaryHeaderRowsYearsData = {
  "AnnRet__1 d": {
    id: "AnnRet__1 d",

    label: "Return (%)",
  },

  "AnnRet__1 m": {
    id: "AnnRet__1 m",

    label: "Return (%)",
  },
  "AnnRet__3 m": {
    id: "AnnRet__3 m",

    label: "Return (%)",
  },
  "AnnRet__6 m": {
    id: "AnnRet__6 m",

    label: "Return (%)",
  },
  "AnnRet__ytd": {
    id: "AnnRet__ytd",

    label: "Return (%)",
  },
  "1 y": {
    id: "1 y",
    label: "Year",
    children: [
      { id: "AnnRet__1 y", label: "Ann. Ret. (%)" },
      { id: "AnnVol__1 y", label: "Ann. Std. (%)" },
      { id: "Sharpe__1 y", label: "Sharpe" },
    ],
  },
};

export const summaryHeaderRows = {
  "3 y": {
    id: "3 y",
    label: "3 Years",
    children: [
      { id: "AnnRet__3 y", label: "Ann. Ret. (%)" },
      { id: "AnnVol__3 y", label: "Ann. Std. (%)" },
      { id: "Sharpe__3 y", label: "Sharpe" },
      { id: "12MDD__3 y", label: "DD12M (%)" },
      { id: "quarter_dd_3 y", label: "QTR_DD (%)" },
      { id: "month_dd_3 y", label: "month_dd" },
      { id: "week_dd_3 y", label: "week_dd" },
      { id: "beta__3 y", label: "beta" },
      { id: "beta_std__3 y", label: "beta std" },
      { id: "alpha__3 y", label: "alpha" },
      { id: "p_value__3 y", label: "p value" },
      { id: "t_stat__3 y", label: "t stat" },
      { id: "beta_plus__3 y", label: "beta plus" },
      { id: "beta_minus__3 y", label: "beta minus" },
      { id: "jensen_alpha__3 y", label: "jensen alpha" },
      // { id: "jensen_sharpe__3 y", label: "jensen sharpe" },
      { id: "maxdd__3 y", label: "maxdd" },
      { id: "sortino__3 y", label: "sortino" },
      { id: "t_alpha__3 y", label: "t_alpha" },
    ],
  },
  "5 y": {
    id: "5 y",
    label: "5 Years",
    children: [
      { id: "AnnRet__5 y", label: "Ann. Ret. (%)" },
      { id: "AnnVol__5 y", label: "Ann. Std. (%)" },
      { id: "Sharpe__5 y", label: "Sharpe" },
      { id: "12MDD__5 y", label: "DD12M (%)" },
      { id: "quarter_dd_5 y", label: "QTR_DD (%)" },
      { id: "month_dd_5 y", label: "month_dd" },
      { id: "week_dd_5 y", label: "week_dd" },
      { id: "beta__5 y", label: "beta" },
      { id: "beta_std__5 y", label: "beta std" },
      { id: "alpha__5 y", label: "alpha" },
      { id: "p_value__5 y", label: "p value" },
      { id: "t_stat__5 y", label: "t stat" },
      { id: "beta_plus__5 y", label: "beta plus" },
      { id: "beta_minus__5 y", label: "beta minus" },
      { id: "jensen_alpha__5 y", label: "jensen alpha" },
      // { id: "jensen_sharpe__5 y", label: "jensen sharpe" },
      { id: "maxdd__5 y", label: "maxdd" },
      { id: "sortino__5 y", label: "sortino" },
      { id: "t_alpha__5 y", label: "t_alpha" },
    ],
  },

  "10 y": {
    id: "10 y",
    label: "10 Years",
    children: [
      { id: "AnnRet__10 y", label: "Ann. Ret. (%)" },
      { id: "AnnVol__10 y", label: "Ann. Std. (%)" },
      { id: "Sharpe__10 y", label: "Sharpe" },
      { id: "12MDD__10 y", label: "DD12M (%)" },
      { id: "quarter_dd_10 y", label: "QTR_DD (%)" },
      { id: "month_dd_10 y", label: "month_dd" },
      { id: "week_dd_10 y", label: "week_dd" },
      { id: "beta__10 y", label: "beta" },
      { id: "beta_std__10 y", label: "beta std" },
      { id: "alpha__10 y", label: "alpha" },
      { id: "p_value__10 y", label: "p value" },
      { id: "t_stat__10 y", label: "t stat" },
      { id: "beta_plus__10 y", label: "beta plus" },
      { id: "beta_minus__10 y", label: "beta minus" },
      { id: "jensen_alpha__10 y", label: "jensen alpha" },
      // { id: "jensen_sharpe__10 y", label: "jensen sharpe" },
    ],
  },

  "15 y": {
    id: "15 y",
    label: "15 Years",
    children: [
      { id: "AnnRet__15 y", label: "Ann. Ret. (%)" },
      { id: "AnnVol__15 y", label: "Ann. Std. (%)" },
      { id: "Sharpe__15 y", label: "Sharpe" },
      { id: "12MDD__15 y", label: "DD12M (%)" },
      { id: "quarter_dd_15 y", label: "QTR_DD (%)" },
      { id: "month_dd_15 y", label: "month_dd" },
      { id: "week_dd_15 y", label: "week_dd" },
      { id: "beta__15 y", label: "beta" },
      { id: "beta_std__15 y", label: "beta std" },
      { id: "alpha__15 y", label: "alpha" },
      { id: "p_value__15 y", label: "p value" },
      { id: "t_stat__15 y", label: "t stat" },
      { id: "beta_plus__15 y", label: "beta plus" },
      { id: "beta_minus__15 y", label: "beta minus" },
      { id: "jensen_alpha__15 y", label: "jensen alpha" },
      // { id: "jensen_sharpe__15 y", label: "jensen sharpe" },
      { id: "maxdd__15 y", label: "maxdd" },
      { id: "sortino__15 y", label: "sortino" },
      { id: "t_alpha__15 y", label: "t_alpha" },
    ],
  },

  "2010-01-01": {
    id: "2010-01-01",
    label: "1/1/2010",
    children: [
      { id: "AnnRet__2010-01-01", label: "Ann. Ret. (%)" },
      { id: "AnnVol__2010-01-01", label: "Ann. Std. (%)" },
      { id: "Sharpe__2010-01-01", label: "Sharpe" },
      { id: "12MDD__2010-01-01", label: "DD12M (%)" },
      { id: "quarter_dd_2010-01-01", label: "QTR_DD (%)" },
      { id: "month_dd_2010-01-01", label: "month_dd" },
      { id: "week_dd_2010-01-01", label: "week_dd" },
      { id: "beta__2010-01-01", label: "beta" },
      { id: "beta_std__2010-01-01", label: "beta std" },
      { id: "alpha__2010-01-01", label: "alpha" },
      { id: "p_value__2010-01-01", label: "p value" },
      { id: "t_stat__2010-01-01", label: "t stat" },
      { id: "beta_plus__2010-01-01", label: "beta plus" },
      { id: "beta_minus__2010-01-01", label: "beta minus" },
      {
        id: "jensen_alpha__2010-01-01",

        label: "jensen alpha",
      },
      // {
      //   id: "jensen_sharpe__2010-01-01",

      //   label: "jensen sharpe",
      // },
      { id: "maxdd__2010-01-01", label: "maxdd" },
      { id: "sortino__2010-01-01", label: "sortino" },
      { id: "t_alpha__2010-01-01", label: "t_alpha" },
    ],
  },

  full: {
    id: "full",
    label: "Full",
    children: [
      { id: "AnnRet__full", label: "Ann. Ret. (%)" },
      { id: "AnnVol__full", label: "Ann. Std. (%)" },
      { id: "Sharpe__full", label: "Sharpe" },
      { id: "12MDD__full", label: "DD12M (%)" },
      { id: "quarter_dd_full", label: "QTR_DD (%)" },
      { id: "month_dd_full", label: "month_dd" },
      { id: "week_dd_full", label: "week_dd" },
      { id: "beta__full", label: "beta" },
      { id: "beta_std__full", label: "beta std" },
      { id: "alpha__full", label: "alpha" },
      { id: "p_value__full", label: "p value" },
      { id: "t_stat__full", label: "t stat" },
      { id: "beta_plus__full", label: "beta plus" },
      { id: "beta_minus__full", label: "beta minus" },
      {
        id: "jensen_alpha__full",

        label: "jensen alpha",
      },
      // {
      //   id: "jensen_sharpe__full",

      //   label: "jensen sharpe",
      // },
      {
        id: "CumRet__full",

        label: "CumRet",
      },
      { id: "maxdd__full", label: "maxdd" },
      { id: "sortino__full", label: "sortino" },
      { id: "t_alpha__full", label: "t_alpha" },
    ],
  },
};

export const summaryYearOptions = ["AnnRet__1 y", "AnnVol__1 y", "Sharpe__1 y"];

export const summary3YearOptions = [
  "AnnRet__3 y",
  "AnnVol__3 y",
  "Sharpe__3 y",
  "12MDD__3 y",
  "quarter_dd_3 y",
  "month_dd_3 y",
  "week_dd_3 y",
  "beta__3 y",
  "beta_std__3 y",
  "alpha__3 y",
  "p_value__3 y",
  "t_stat__3 y",
  "beta_plus__3 y",
  "beta_minus__3 y",
  "jensen_alpha__3 y",
  "jensen_sharpe__3 y",
  "maxdd__3 y",
  "sortino__3 y",
  "t_alpha__3 y",
];

export const summary5YearOptions = [
  "AnnRet__5 y",
  "AnnVol__5 y",
  "Sharpe__5 y",
  "12MDD__5 y",
  "quarter_dd_5 y",
  "month_dd_5 y",
  "week_dd_5 y",
  "beta__5 y",
  "beta_std__5 y",
  "alpha__5 y",
  "p_value__5 y",
  "t_stat__5 y",
  "beta_plus__5 y",
  "beta_minus__5 y",
  "jensen_alpha__5 y",
  "jensen_sharpe__5 y",
  "maxdd__5 y",
  "sortino__5 y",
  "t_alpha__5 y",
];

export const summary10YearOptions = [
  "AnnRet__10 y",
  "AnnVol__10 y",
  "Sharpe__10 y",
  "12MDD__10 y",
  "quarter_dd_10 y",
  "month_dd_10 y",
  "week_dd_10 y",
  "beta__10 y",
  "beta_std__10 y",
  "alpha__10 y",
  "p_value__10 y",
  "t_stat__10 y",
  "beta_plus__10 y",
  "beta_minus__10 y",
  "jensen_alpha__10 y",
  "jensen_sharpe__10 y",
];

export const summary15YearOptions = [
  "AnnRet__15 y",
  "AnnVol__15 y",
  "Sharpe__15 y",
  "12MDD__15 y",
  "quarter_dd_15 y",
  "month_dd_15 y",
  "week_dd_15 y",
  "beta__15 y",
  "beta_std__15 y",
  "alpha__15 y",
  "p_value__15 y",
  "t_stat__15 y",
  "beta_plus__15 y",
  "beta_minus__15 y",
  "jensen_alpha__15 y",
  "jensen_sharpe__15 y",
  "maxdd__15 y",
  "sortino__15 y",
  "t_alpha__15 y",
];

export const summarySince2010Options = [
  "AnnRet__2010-01-01",
  "AnnVol__2010-01-01",
  "Sharpe__2010-01-01",
  "12MDD__2010-01-01",
  "quarter_dd_2010-01-01",
  "month_dd_2010-01-01",
  "week_dd_2010-01-01",
  "beta__2010-01-01",
  "beta_std__2010-01-01",
  "alpha__2010-01-01",
  "p_value__2010-01-01",
  "t_stat__2010-01-01",
  "beta_plus__2010-01-01",
  "beta_minus__2010-01-01",
  "jensen_alpha__2010-01-01",
  "jensen_sharpe__2010-01-01",
  "maxdd__2010-01-01",
  "sortino__2010-01-01",
  "t_alpha__2010-01-01",
];

export const summaryFullOptions = [
  "AnnRet__full",
  "AnnVol__full",
  "Sharpe__full",
  "12MDD__full",
  "quarter_dd_full",
  "month_dd_full",
  "week_dd_full",
  "beta__full",
  "beta_std__full",
  "alpha__full",
  "p_value__full",
  "t_stat__full",
  "beta_plus__full",
  "beta_minus__full",
  "jensen_alpha__full",
  "jensen_sharpe__full",
  "maxdd__full",
  "sortino__full",
  "t_alpha__full",
];

export const summaryTableOptions = [
  ...summaryYearOptions,
  ...summary3YearOptions,
  ...summary5YearOptions,
  ...summary10YearOptions,
  ...summary15YearOptions,
  // ...summarySince2010Options,
  ...summaryFullOptions,
];

export const summaryBetaOptions = [
  "beta__3 y",
  "beta__5 y",
  "beta__10 y",
  // "beta__2010-01-01",
  "beta__15 y",
  "beta__full",
];
export const summaryBetaStdOptions = [
  "beta_std__3 y",
  "beta_std__5 y",
  "beta_std__10 y",
  // "beta_std__2010-01-01",
  "beta_std__15 y",
  "beta_std__full",
];
export const summaryAlphaOptions = [
  "alpha__3 y",
  "alpha__5 y",
  "alpha__10 y",
  // "alpha__2010-01-01",
  "alpha__15 y",
  "alpha__full",
];
export const summaryPValueOptions = [
  "p_value__3 y",
  "p_value__5 y",
  "p_value__10 y",
  // "p_value__2010-01-01",
  "p_value__15 y",
  "p_value__full",
];
export const summaryTStatOptions = [
  "t_stat__3 y",
  "t_stat__5 y",
  "t_stat__10 y",
  // "t_stat__2010-01-01",
  "t_stat__15 y",
  "t_stat__full",
];
export const summaryJensenAlphaOptions = [
  "jensen_alpha__3 y",
  "jensen_alpha__5 y",
  "jensen_alpha__10 y",
  // "jensen_alpha__2010-01-01",
  "jensen_alpha__15 y",
  "jensen_alpha__full",
];
export const summaryJensenSharpeOptions = [
  "jensen_sharpe__3 y",
  "jensen_sharpe__5 y",
  "jensen_sharpe__10 y",
  // "jensen_sharpe__2010-01-01",
  "jensen_sharpe__15 y",
  "jensen_sharpe__full",
];
export const summaryBetaPlusOptions = [
  "beta_plus__3 y",
  "beta_plus__5 y",
  "beta_plus__10 y",
  // "beta_plus__2010-01-01",
  "beta_plus__15 y",
  "beta_plus__full",
];
export const summaryBetaMinusOptions = [
  "beta_minus__3 y",
  "beta_minus__5 y",
  "beta_minus__10 y",
  // "beta_minus__2010-01-01",
  "beta_minus__15 y",
  "beta_minus__full",
];

export const summaryMaxddOptions = [
  "maxdd__3 y",
  "maxdd__5 y",
  "maxdd__10 y",
  // "maxdd__2010-01-01",
  "maxdd__15 y",
  "maxdd__full",
];

export const summaryQuarterddOptions = [
  "quarter_dd_3 y",
  "quarter_dd_5 y",
  "quarter_dd_10 y",
  // "quarter_dd_2010-01-01",
  "quarter_dd_15 y",
  "quarter_dd_full",
];

export const summarySortinoOptions = [
  "sortino__3 y",
  "sortino__5 y",
  "sortino__10 y",
  // "sortino__2010-01-01",
  "sortino__15 y",
  "sortino__full",
];

export const summaryTAlphaOptions = [
  "t_alpha__3 y",
  "t_alpha__5 y",
  "t_alpha__10 y",
  // "t_alpha__2010-01-01",
  "t_alpha__15 y",
  "t_alpha__full",
];

export const summaryTemplateSort = [
  "AnnRet__1 d",
  "AnnRet__1 m",
  "AnnRet__3 m",
  "AnnRet__6 m",
  "AnnRet__1 y",
  "AnnVol__1 y",
  "Sharpe__1 y",
  "AnnRet__3 y",
  "AnnVol__3 y",
  "Sharpe__3 y",
  "12MDD__3 y",
  "quarter_dd_3 y",
  "month_dd_3 y",
  "week_dd_3 y",
  "beta__3 y",
  "beta_std__3 y",
  "alpha__3 y",
  "p_value__3 y",
  "t_stat__3 y",
  "beta_plus__3 y",
  "beta_minus__3 y",
  "jensen_alpha__3 y",
  "jensen_sharpe__3 y",
  "AnnRet__5 y",
  "AnnVol__5 y",
  "Sharpe__5 y",
  "12MDD__5 y",
  "quarter_dd_5 y",
  "month_dd_5 y",
  "week_dd_5 y",
  "beta__5 y",
  "beta_std__5 y",
  "alpha__5 y",
  "p_value__5 y",
  "t_stat__5 y",
  "beta_plus__5 y",
  "beta_minus__5 y",
  "jensen_alpha__5 y",
  "jensen_sharpe__5 y",
  "AnnRet__10 y",
  "AnnVol__10 y",
  "Sharpe__10 y",
  "12MDD__10 y",
  "quarter_dd_10 y",
  "month_dd_10 y",
  "week_dd_10 y",
  "beta__10 y",
  "beta_std__10 y",
  "alpha__10 y",
  "p_value__10 y",
  "t_stat__10 y",
  "beta_plus__10 y",
  "beta_minus__10 y",
  "jensen_alpha__10 y",
  "jensen_sharpe__10 y",
  // "AnnRet__2010-01-01",
  // "AnnVol__2010-01-01",
  // "Sharpe__2010-01-01",
  // "12MDD__2010-01-01",
  // "quarter_dd_2010-01-01",
  // "month_dd_2010-01-01",
  // "week_dd_2010-01-01",
  // "beta__2010-01-01",
  // "beta_std__2010-01-01",
  // "alpha__2010-01-01",
  // "p_value__2010-01-01",
  // "t_stat__2010-01-01",
  // "beta_plus__2010-01-01",
  // "beta_minus__2010-01-01",
  // "jensen_alpha__2010-01-01",
  // "jensen_sharpe__2010-01-01",
  "AnnRet__15 y",
  "AnnVol__15 y",
  "Sharpe__15 y",
  "12MDD__15 y",
  "quarter_dd_15 y",
  "month_dd_15 y",
  "week_dd_15 y",
  "beta__15 y",
  "beta_std__15 y",
  "alpha__15 y",
  "p_value__15 y",
  "t_stat__15 y",
  "beta_plus__15 y",
  "beta_minus__15 y",
  "jensen_alpha__15 y",
  "jensen_sharpe__15 y",
  "AnnRet__full",
  "AnnVol__full",
  "Sharpe__full",
  "12MDD__full",
  "quarter_dd_full",
  "month_dd_full",
  "week_dd_full",
  "beta__full",
  "beta_std__full",
  "alpha__full",
  "p_value__full",
  "t_stat__full",
  "beta_plus__full",
  "beta_minus__full",
  "jensen_alpha__full",
  "jensen_sharpe__full",
  "CumRet__full",
];

export const dataTabIds = {
  summary: "summary",
  return: "return",
  drowdawn: "drowdawn",
  correlation: "correlation",
  compositions: "compositions",
};

export const printMap = {
  [dataTabIds.summary]: (tab) => printSummaryTab(tab),
  [dataTabIds.return]: (tab) => printTab(tab),
  [dataTabIds.drowdawn]: (tab) => printDrowdawnTab(tab),
  [dataTabIds.correlation]: (tab) => printTab(tab),
  [dataTabIds.compositions]: (tab) => printTab(tab),
};

export const excelExportMap = {
  [dataTabIds.summary]: (tab) => excelExportSummaryTab(tab),
  [dataTabIds.return]: (tab, strategiesStatistics) => excelExportReturnTab(tab, strategiesStatistics),
  [dataTabIds.drowdawn]: (tab) => {
    excelExportDrawDownTab(tab);
  },
  [dataTabIds.correlation]: (tab) => {
    excelExportCorrelationTab(tab);
  },
  [dataTabIds.compositions]: (tab) => {
    SnackBarUtils.warning("Compositions tab currently not available");
  },
};
