import React, { useState, useEffect } from "react";
import AdditionalApi from "../../../services/Additional";
import { Modal, Box, Typography, IconButton, Fade } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function StrategyAdditions({ openStrategyAddition = false, handleOpenStrategyAddition }) {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const token = user?.access_token || "";

  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    if (openStrategyAddition && fetchedData.length === 0) {
      fetchTextFromAPI();
    }
  }, [openStrategyAddition]);
  

  const fetchTextFromAPI = async () => {
    try {
      if (!token) {
        console.warn("No token available");
        return;
      }
      const response = await AdditionalApi.fetchSheetData(token);
      setFetchedData(response);
    } catch (error) {
      handleOpenStrategyAddition(false);
    }
  };

  return (
    <Modal
      open={openStrategyAddition}
      onClose={() => handleOpenStrategyAddition(false)}
      closeAfterTransition
    >
      <Fade in={openStrategyAddition}>
      <Box 
          sx={{ 
            p: 3, 
            backgroundColor: "white", 
            borderRadius: 2, 
            maxWidth: '60%', 
            mx: "auto", 
            mt: 5 
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleOpenStrategyAddition(false)}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Typography align="center" variant="h6" sx={{ mb: 2 }}>
              Recent Strategy Additions
          </Typography>

          {/* Scrollable Content */}
          <Box sx={{ maxHeight: 500, overflowY: "auto", p: 1 }}>
            {fetchedData.length > 0 ? (
              fetchedData.map((item, index) => (
                <Box key={index} sx={{ mb: 2, p: 2, border: "1px solid #ccc", borderRadius: 1 }}>
                  <Typography variant="h6">{item.Name}</Typography>
                  <Typography>{item.Description}</Typography>
                </Box>
              ))
            ) : (
              <Typography align="center">No strategies available</Typography>
            )}
          </Box>
        </Box>

      </Fade>
    </Modal>
  );
}

export default StrategyAdditions;
