import { mainUrl } from "../config";
import axios from "axios";
import { generateErrorMessage } from "../helpers/Error";

const fetchSheetData = async (token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return axios
    .post(`${mainUrl.API_URL}/fetch-sheet-data`, {}, config)
    .then((response) => response.data)
    .catch((err) => {
      generateErrorMessage(err);
      return [];
    });

};

const AdditionalApi = {
  fetchSheetData,
};

export default AdditionalApi;
